import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { useEffect, useState } from "react";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import ApexChart from "react-apexcharts";
import moment from 'moment';
import { apiService } from '../ApiClient';
import { organizationId } from '../auth/authConfig';
export const OccupancyChartWidget = ({ title, ids, icon, color, telemetryClient }) => {
    const [_value, _setValue] = useState(0);
    const [_values, _setValues] = useState([]);
    const [_data, _setData] = useState([]);
    const [_options, _setOptions] = useState({
        chart: {
            height: '100%',
            toolbar: {
                show: false,
            },
            animations: {
                enabled: true,
                easing: 'easeinout',
                speed: 800,
                animateGradually: {
                    enabled: true,
                    delay: 150
                },
                dynamicAnimation: {
                    enabled: true,
                    speed: 350
                }
            }
        },
        markers: {
            size: 3,
            colors: color,
            strokeColors: color
        },
        dataLabels: {
            enabled: false
        },
        grid: {
            show: false,
        },
        xaxis: {
            type: 'datetime',
            labels: {
                show: false,
                datetimeFormatter: {
                    year: 'yyyy',
                    month: 'MMM \'yy',
                    day: 'dd MMM',
                    hour: 'HH:mm'
                },
                datetimeUTC: true
            },
            axisBorder: {
                show: false,
            },
            axisTicks: {
                show: false
            }
        },
        yaxis: {
            show: true,
            opposite: true,
            axisBorder: {
                show: false
            },
            forceNiceScale: true,
            decimalsInFloat: 2,
            labels: {
                formatter: function (val) {
                    return val.toFixed(0);
                },
                style: {
                    colors: '#CCC'
                }
            }
        },
        series: [
            {
                name: title,
                color: color,
                data: []
            }
        ]
    });
    const [_date, _setDate] = useState(new Date());
    let _start = moment().subtract(24, 'hours').toDate();
    let _end = moment().toDate();
    const _handler = (event) => {
        if (Array.isArray(event)) {
            _setData(actual => [...actual.filter(v => { return event.findIndex(e => e.id === v.id) !== -1; }), ...event]);
        }
        else {
            _setData(actual => [
                ...actual.filter(v => { return v.id !== event.id; }),
                event
            ]);
        }
        _setDate(new Date());
    };
    useEffect(() => {
        Promise.resolve(apiService.getTelemetryHistory(organizationId, ids, _start, _end))
            .then(telemetries => {
            var __data = [];
            var __distinct = [];
            var __values = [];
            for (const telemetry of telemetries)
                __data = [...__data, ...telemetry.data.map(d => { return { id: telemetry.id, date: d.date, value: d.value }; })];
            __data = __data.sort(function (a, b) { return new Date(a.date).valueOf() - new Date(b.date).valueOf(); });
            console.log(__data);
            for (const data of __data) {
                const __index = __distinct.findIndex(d => d.id == data.id);
                if (__index === -1)
                    __distinct.push(data);
                else
                    __distinct[__index] = data;
                const __total = __distinct.filter(v => v.value === true || v.value.toString().toLowerCase() === "true").length;
                __values = [...__values, { date: data.date, value: __total }];
            }
            _setData(__distinct);
            _setValue(__values[__values.length - 1].value);
            _setValues(__values);
            telemetryClient.SubscribeToId(ids, _handler, false);
        });
    }, []);
    useEffect(() => {
        if (_data.length === 0)
            return;
        const total = _data.filter(v => v.value === true || v.value.toString().toLowerCase() === "true").length;
        _setValue(total);
    }, [_data]);
    useEffect(() => _setValues(actual => [...actual, { date: new Date(), value: _value }]), [_value]);
    useEffect(() => {
        _setOptions(actual => {
            return Object.assign(Object.assign({}, actual), { series: [
                    {
                        name: title,
                        color: color,
                        data: _values.map(v => {
                            return { x: v.date, y: v.value };
                        })
                    }
                ] });
        });
    }, [_values]);
    return (_jsxs("div", { className: "container", children: [_jsx("div", { className: "title", children: title }), _jsxs("div", { className: "wrapper", children: [_jsxs("div", { className: "current", children: [_jsx("div", { className: "icon", children: _jsx(FontAwesomeIcon, { icon: icon, style: { color: color, '--fa-primary-color': color, "--fa-secondary-color": "#4BACC6" } }) }), _jsx("div", { className: "value-wrapper", children: _jsxs("div", { className: "value", children: [_value, _jsxs("span", { className: "unit", children: ["/", ids.length] })] }) })] }), _jsx("div", { className: "chart", children: _jsx(ApexChart, { options: _options, series: _options.series, type: "area", height: "100%" }) })] }), _jsx("div", { className: "footer", children: _date.toLocaleTimeString('nl-NL') })] }));
};
