import { Query } from "./classes/query";
class ODataClient {
    constructor(axios) {
        this.axios = axios;
    }
    query() {
        return new Query(this.axios);
    }
}
export default ODataClient;
