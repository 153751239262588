import { PublicClientApplication } from "@azure/msal-browser";
import { msalConfig } from "./authConfig";
const _instance = new PublicClientApplication(msalConfig);
_instance.initialize();
export default class AuthService {
    constructor() {
        this.instance = _instance;
        this.user = () => _instance.getAllAccounts()[0];
        this.isAuthenticated = () => _instance.getAllAccounts().length > 0;
        this.login = () => {
        };
    }
}
export const authService = new AuthService();
