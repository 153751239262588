import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { useEffect, useState } from "react";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
export const OccupancyWidget = ({ title, ids, icon, color, horizontal, telemetryClient }) => {
    const [_value, _setValue] = useState(0);
    const [_values, _setValues] = useState([]);
    const [_date, _setDate] = useState(new Date());
    const _handler = (event) => {
        if (Array.isArray(event)) {
            _setValues(actual => [...actual.filter(v => { return event.findIndex(e => e.id === v.id) !== -1; }), ...event]);
        }
        else {
            _setValues(actual => [
                ...actual.filter(v => { return v.id !== event.id; }),
                event
            ]);
        }
        _setDate(new Date());
    };
    useEffect(() => telemetryClient.SubscribeToId(ids, _handler), []);
    useEffect(() => {
        if (_values.length === 0)
            return;
        const _total = _values.filter(v => v.value === true || v.value.toString().toLowerCase() === "true").length;
        _setValue(_total);
    }, [_values]);
    return (_jsxs("div", { className: "container", children: [_jsx("div", { className: "title", children: title }), _jsxs("div", { className: horizontal === true ? "content horizontal" : "content", children: [_jsx("div", { className: "icon", children: _jsx(FontAwesomeIcon, { icon: icon, style: { color: color, '--fa-primary-color': color, "--fa-secondary-color": "#4BACC6" } }) }), _jsx("div", { className: "value-wrapper", children: _jsxs("div", { className: "value", children: [_value, _jsxs("span", { className: "unit", children: ["/", ids.length] })] }) })] }), _jsx("div", { className: "footer", children: _date.toLocaleTimeString('nl-NL') })] }));
};
