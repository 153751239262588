import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { useEffect, useState } from "react";
import ApexChart from "react-apexcharts";
import { apiService } from '../ApiClient';
import moment from 'moment';
import { decode } from 'html-entities';
import { organizationId } from '../auth/authConfig';
export const ChartWidget = ({ title, ids, names, colors, units, telemetryClient }) => {
    const [_date, _setDate] = useState(new Date());
    const [_values, _setValues] = useState([]);
    const [_options, _setOptions] = useState({
        chart: {
            height: '100%',
            toolbar: {
                show: false,
            },
            animations: {
                enabled: true,
                easing: 'easeinout',
                speed: 800,
                animateGradually: {
                    enabled: true,
                    delay: 150
                },
                dynamicAnimation: {
                    enabled: true,
                    speed: 350
                }
            }
        },
        xaxis: {
            type: 'datetime',
            labels: {
                datetimeFormatter: {
                    year: 'yyyy',
                    month: 'MMM \'yy',
                    day: 'dd MMM',
                    hour: 'HH:mm'
                },
                datetimeUTC: true
            }
        },
        yaxis: ids.map((id, index) => {
            return {
                forceNiceScale: true,
                decimalsInFloat: 2,
                opposite: index % 2 !== 0,
                labels: {
                    formatter: function (val) {
                        return val.toFixed(0) + decode(units[index]);
                    }
                }
            };
        }),
        series: ids.map((id, index) => {
            return {
                name: names[index],
                color: colors[index],
                data: []
            };
        })
    });
    let _start = moment().subtract(24, 'hours').toDate();
    let _end = moment().toDate();
    const _handler = (event) => {
        let _start = moment().subtract(24, 'hours').toDate();
        let _end = moment().toDate();
        if (Array.isArray(event))
            _setValues(actual => { return [...actual, ...event].filter(e => new Date(e.date) >= _start); });
        else
            _setValues(actual => { return [...actual, event].filter(e => new Date(e.date) >= _start); });
        _setDate(_end);
    };
    useEffect(() => {
        Promise.resolve(apiService.getTelemetryHistory(organizationId, ids, _start, _end))
            .then(values => {
            var _v = [];
            for (const v1 of values)
                _v = [..._v, ...v1.data.map(v2 => {
                        return {
                            id: v1.id,
                            model: '',
                            date: v2.date,
                            value: v2.value
                        };
                    })];
            _setValues(_v);
            telemetryClient.SubscribeToId(ids, _handler, false);
        });
    }, []);
    useEffect(() => {
        _setOptions(actual => {
            return Object.assign(Object.assign({}, actual), { series: ids.map((id, index) => {
                    return {
                        name: names[index],
                        color: colors[index],
                        data: _values.filter(v => v.id === id).map(v => { return { x: v.date, y: v.value }; })
                    };
                }) });
        });
        _setDate(new Date());
    }, [_values]);
    return (_jsxs("div", { className: "container", children: [_jsx("div", { className: "title", children: title }), _jsx("div", { className: "content", children: _jsx(ApexChart, { options: _options, series: _options.series, type: "line", height: "100%" }) }), _jsx("div", { className: "footer", children: _date.toLocaleTimeString('nl-NL') })] }));
};
