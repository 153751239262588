var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
export class Query {
    constructor(axios) {
        this.url = "";
        this.axios = axios;
        this.parameters = {};
    }
    top(rowNumber) {
        if (this.parameters["$skip"] === undefined) {
            console.warn("overridden $skip property, it was already been set");
        }
        this.parameters["$top"] = rowNumber;
        return this;
    }
    skip(rowNumber) {
        if (this.parameters["$skip"] === undefined) {
            console.warn("overridden $top property, it was already been set");
        }
        this.parameters["$skip"] = rowNumber;
        return this;
    }
    paginate(page, rows) {
        this.parameters["$top"] = rows;
        this.parameters["$skip"] = (page - 1) * rows;
        return this;
    }
    property(key) {
        this.url += `/${key}`;
        return this;
    }
    /**
     * Retrieves a single entity
     * @param entity entity to inspect
     * @param id id of the entity to retrieve
     * @returns Entity interface
     */
    entity(entity, id) {
        this.url += `/${entity}(${typeof id === "number" ? id : `'${id}'`})`;
        return this;
    }
    /**
     *
     * Retrieves a collection of entities
     *
     * @param resource resource to query
     * @returns EntitySet
     */
    entities(resource) {
        this.url += `/${resource}`;
        return this;
    }
    // TODO: Add a query ORM.
    /**
     * Applies a filter to the query.
     * All the concatenated filters are in AND
     * @param filterquery filter to apply
     * @returns
     */
    filter(filterquery) {
        let filter = this.parameters["$filter"];
        if (filter === undefined) {
            filter = filterquery;
        }
        else {
            filter = filter + ` and ${filterquery}`;
        }
        this.parameters["$filter"] = filter;
        return this;
    }
    select(fields) {
        let params = this.parameters["$select"];
        if (params === undefined) {
            if (typeof fields === "string") {
                params = fields;
            }
            else {
                params = "";
                for (let field of fields) {
                    params += field + ", ";
                }
                params = params.slice(0, -2);
            }
        }
        else {
            if (typeof fields === "string") {
                params += fields;
            }
            else {
                for (let field of fields) {
                    params += field + ", ";
                }
                params = params.slice(0, -2);
            }
        }
        this.parameters["$select"] = params;
        return this;
    }
    count() {
        this.url += "/$count";
        return this;
    }
    execute() {
        return __awaiter(this, void 0, void 0, function* () {
            return this.axios.get(this.url, {
                params: this.parameters,
            });
        });
    }
    value() {
        this.url += "/$value";
        return this;
    }
    getURL() {
        return this.url;
    }
}
