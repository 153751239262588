var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import { EventHubConsumerClient, latestEventPosition } from "@azure/event-hubs";
import { apiService } from './ApiClient';
import { organizationId } from './auth/authConfig';
export class TelemetryClient {
    constructor(connectionString) {
        this._modelSubscriptions = [];
        this._idSubscriptions = [];
        this._eventHandler = (events, context) => __awaiter(this, void 0, void 0, function* () {
            var _a, _b, _c, _d, _e;
            if (events.length === 0)
                return;
            for (const event of events) {
                if (!event.body.hasOwnProperty('patch'))
                    continue;
                var _model = (_a = event.body.modelId) !== null && _a !== void 0 ? _a : '';
                var _id = (_c = (_b = event.properties) === null || _b === void 0 ? void 0 : _b["cloudEvents:subject"]) !== null && _c !== void 0 ? _c : '';
                var _date = (_e = new Date((_d = event.properties) === null || _d === void 0 ? void 0 : _d["cloudEvents:time"])) !== null && _e !== void 0 ? _e : Date.now();
                var _patches = event.body.patch;
                for (const _patch of _patches.filter(p => p.path === "/lastValue")) {
                    var _value = _patch.value;
                    var _event = {
                        model: _model,
                        id: _id,
                        date: _date,
                        value: _value
                    };
                    var _modelSubscriptions = this._modelSubscriptions.filter(s => { s.id === _model; });
                    _modelSubscriptions.forEach(s => { s.handler(_event); });
                    var _idSubscriptions = this._idSubscriptions.filter(s => {
                        if (s.id === _id)
                            return true;
                        if (Array.isArray(s.id) && s.id.includes(_id))
                            return true;
                        return false;
                    });
                    _idSubscriptions.forEach(s => s.handler(_event));
                }
            }
        });
        this.SubscribeToModel = (model, handler) => {
            this._modelSubscriptions.push({ id: model, handler: handler });
        };
        this.SubscribeToId = (id, handler, initialValue = true) => {
            this._idSubscriptions.push({ id: id, handler: handler });
            if (initialValue) {
                const _ids = Array.isArray(id) ? id : [id];
                Promise.resolve(apiService.getTelemetryCurrent(organizationId, _ids))
                    .then(value => {
                    var _values = value.map(v => {
                        return {
                            model: '',
                            id: id,
                            date: v.data[0].date,
                            value: v.data[0].value
                        };
                    });
                    handler(_values);
                });
            }
        };
        this.Close = () => {
            this._subscription.close();
            this._consumerClient.close();
        };
        this._consumerClient = new EventHubConsumerClient("$default", connectionString);
        this._subscription = this._consumerClient.subscribe({
            processEvents: this._eventHandler,
            processError: (err, context) => __awaiter(this, void 0, void 0, function* () { return console.log(`Errors in subscription to partition ${context.partitionId}: ${err}`); })
        }, { startPosition: latestEventPosition });
    }
}
