import { LogLevel } from "@azure/msal-browser";
export const msalConfig = {
    auth: {
        clientId: "150159fb-06e7-498f-910c-c9a6daf36a77",
        authority: "https://login.microsoftonline.com/7a87dcbd-6ec1-4ba4-8417-d36a7d37bf7e",
        redirectUri: "https://showroom.acc.pulse.services"
    },
    cache: {
        cacheLocation: "sessionStorage",
        storeAuthStateInCookie: true, // Set this to "true" if you are having issues on IE11 or Edge
    },
    system: {
        loggerOptions: {
            loggerCallback: (level, message, containsPii) => {
                if (containsPii) {
                    return;
                }
                switch (level) {
                    case LogLevel.Error:
                        console.error(message);
                        return;
                    case LogLevel.Info:
                        console.info(message);
                        return;
                    case LogLevel.Verbose:
                        console.debug(message);
                        return;
                    case LogLevel.Warning:
                        console.warn(message);
                        return;
                }
            },
        },
    },
};
export const organizationId = "A914ED9E-367A-47BF-AE25-F745099DAB7E";
export const connectionString = "Endpoint=sb://sbeventsacceuw01.servicebus.windows.net/;SharedAccessKeyName=Dashboard;SharedAccessKey=/jVyv27hApDkDyBjEq1H5uz/mlMm+6ihC+AEhHMTJJM=;EntityPath=sbeventdthistoryeuw02";
