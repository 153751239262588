var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import ODataClient from "./modules/axios-odata";
import axios from "axios";
import { authService } from "./auth/authService";
const _dashboardInstance = axios.create();
_dashboardInstance.interceptors.request.use((request) => __awaiter(void 0, void 0, void 0, function* () {
    const _account = authService.instance.getAllAccounts()[0];
    const _token = yield authService.instance.acquireTokenSilent({ scopes: ['api://c71573a2-705d-47d3-965f-8546c8a68faa/Api.Access'], account: _account });
    const _headers = Object.assign(Object.assign({}, request.headers), { Authorization: 'Bearer ' + (_token === null || _token === void 0 ? void 0 : _token.accessToken) });
    request.headers = _headers;
    return request;
}), (err) => {
    return Promise.reject(err);
});
const _odataConfig = {
    baseURL: "https://sbodataapiacceuw01.azurewebsites.net",
    timeout: 30000,
};
const _odataInstance = axios.create(_odataConfig);
_odataInstance.interceptors.request.use((request) => __awaiter(void 0, void 0, void 0, function* () {
    const _account = authService.instance.getAllAccounts()[0];
    const _token = yield authService.instance.acquireTokenSilent({ scopes: ['api://c71573a2-705d-47d3-965f-8546c8a68faa/Api.Access'], account: _account });
    const _headers = Object.assign(Object.assign({}, request.headers), { Authorization: 'Bearer ' + (_token === null || _token === void 0 ? void 0 : _token.accessToken) });
    request.headers = _headers;
    return request;
}), (err) => {
    return Promise.reject(err);
});
const _oDataClient = new ODataClient(_odataInstance);
export default class ApiService {
    constructor() {
        this.getOrganizations = () => __awaiter(this, void 0, void 0, function* () {
            const response = yield _dashboardInstance.get('/api/organizations');
            const data = yield response.data;
            return data;
        });
        this.setOrganization = (organizationId) => __awaiter(this, void 0, void 0, function* () {
            const response = yield _dashboardInstance.patch('/api/organizations/' + organizationId);
            return response.status == 200;
        });
        this.getDashboards = (organizationId) => __awaiter(this, void 0, void 0, function* () {
            const response = yield _dashboardInstance.get('/api/organizations/' + organizationId + '/dashboards');
            const data = yield response.data;
            return data;
        });
        this.getDashboard = (organizationId, dashboardId) => __awaiter(this, void 0, void 0, function* () {
            const response = yield _dashboardInstance.get('/api/organizations/' + organizationId + '/dashboards/' + dashboardId);
            const data = yield response.data;
            return data;
        });
        this.addDashboard = (organizationId, name, shared) => __awaiter(this, void 0, void 0, function* () {
            const _dashboard = {
                name: name,
                shared: shared
            };
            const dashboard = yield _dashboardInstance.post('/api/organizations/' + organizationId + '/dashboards', _dashboard);
            const data = yield dashboard.data;
            return data;
        });
        this.editDashboard = (organizationId, dashboardId, dashboard) => __awaiter(this, void 0, void 0, function* () {
            dashboard.id = dashboardId;
            const data = yield _dashboardInstance.put('/api/organizations/' + organizationId + '/dashboards/' + dashboardId, dashboard);
            return data.status == 200;
        });
        this.getHierachy = (organizationId, parentId) => __awaiter(this, void 0, void 0, function* () {
            let _uri = '/api/organizations/' + organizationId + '/hierachy';
            if (parentId)
                _uri += '/' + parentId;
            const response = yield _dashboardInstance.get(_uri);
            const data = yield response.data;
            return data;
        });
        this.getSpaces = (organizationId) => __awaiter(this, void 0, void 0, function* () {
            let _result = yield _oDataClient.query()
                .entities(organizationId + '/spaces')
                .execute();
            if (_result.status !== 200)
                return [];
            return _result.data
                .map((r) => { var _a; return { id: r.id, name: (_a = r.name) !== null && _a !== void 0 ? _a : r.id }; })
                .sort((a, b) => a.name.toLowerCase() > b.name.toLowerCase() ? 1 : -1);
        });
        this.getSpaceIdFor = (organizationId, meterId) => __awaiter(this, void 0, void 0, function* () {
            let _result = yield _oDataClient.query()
                .entities(organizationId + '/meters?key=' + meterId)
                .execute();
            if (_result.status !== 200)
                return [];
            return _result.data[0].spaceIds[0];
        });
        this.getMeters = (organizationId, spaceId) => __awaiter(this, void 0, void 0, function* () {
            let _result = spaceId ?
                yield _oDataClient.query()
                    .entities(organizationId + '/spaces(' + spaceId + ')/meters')
                    .execute() :
                yield _oDataClient.query()
                    .entities(organizationId + '/meters')
                    .execute();
            if (_result.status !== 200)
                return [];
            return _result.data
                .map((r) => { var _a, _b; return { id: r.id, spaceId: (_a = r.spaceIds) === null || _a === void 0 ? void 0 : _a[0], name: (_b = r.name) !== null && _b !== void 0 ? _b : r.id }; })
                .sort((a, b) => a.name.toLowerCase() > b.name.toLowerCase() ? 1 : -1);
        });
        this.getMeterIdFor = (organizationId, capabilityId) => __awaiter(this, void 0, void 0, function* () {
            let _result = yield _oDataClient.query()
                .entities(organizationId + '/capabilities?key=' + capabilityId)
                .execute();
            if (_result.status !== 200)
                return [];
            return _result.data[0].meterIds[0];
        });
        this.getCapabilities = (organizationId, meterId) => __awaiter(this, void 0, void 0, function* () {
            let _result = meterId ?
                yield _oDataClient.query()
                    .entities(organizationId + '/meters(' + meterId + ')/capabilities')
                    .execute() :
                yield _oDataClient.query()
                    .entities(organizationId + '/capabilities')
                    .execute();
            if (_result.status !== 200)
                return [];
            return _result.data
                .map((r) => { var _a, _b; return { id: r.id, meterId: (_a = r.meterIds) === null || _a === void 0 ? void 0 : _a[0], name: (_b = r.name) !== null && _b !== void 0 ? _b : r.id }; })
                .sort((a, b) => a.name.toLowerCase() > b.name.toLowerCase() ? 1 : -1);
        });
        this.getTelemetryOptions = (organizationId) => __awaiter(this, void 0, void 0, function* () {
            var _spaces = yield this.getSpaces(organizationId);
            var _meters = yield this.getMeters(organizationId);
            var _capabilities = yield this.getCapabilities(organizationId);
            var _result = _spaces.map(s => {
                return {
                    id: s.id,
                    name: s.name,
                    meters: _meters.filter(m => m.spaceId == s.id).map(m => {
                        return {
                            id: m.id,
                            spaceId: m.spaceId,
                            name: m.name,
                            capabilities: _capabilities.filter(c => c.meterId == m.id)
                        };
                    })
                };
            });
            return _result;
        });
        this.getTelemetryHistory = (organizationId, telemetryIds, start, end) => __awaiter(this, void 0, void 0, function* () {
            let _result = yield _oDataClient.query()
                .entities(organizationId + '/telemetry?key=' + telemetryIds.join('&key=') + '&start=' + JSON.stringify(start).replace(/"|'/g, '') + '&end=' + JSON.stringify(end).replace(/"|'/g, ''))
                .execute();
            if (_result.status !== 200)
                return [];
            return _result.data;
        });
        this.getTelemetryCurrent = (organizationId, telemetryIds) => __awaiter(this, void 0, void 0, function* () {
            let _result = yield _oDataClient.query()
                .entities(organizationId + '/telemetry?key=' + telemetryIds.join('&key='))
                .execute();
            if (_result.status !== 200)
                return [];
            return _result.data;
        });
        this.getFiles = (organizationId) => __awaiter(this, void 0, void 0, function* () {
            const response = yield _dashboardInstance.get('/api/organizations/' + organizationId + '/files');
            const data = yield response.data;
            return data;
        });
        this.uploadFile = (organizationId, file) => __awaiter(this, void 0, void 0, function* () {
            const _form = new FormData();
            _form.append("file", file);
            const response = yield _dashboardInstance.post('/api/organizations/' + organizationId + '/files', _form);
            return response.data;
        });
    }
}
export const apiService = new ApiService();
